import React, { useState } from 'react';
import { Form, Input, Button, Layout, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Dashboard from './Dashboard'; 

const { Content } = Layout;

const LoginPage = () => {
  const [authenticated, setAuthenticated] = useState(false); // Track authentication state

  // Hardcoded credentials
  const hardcodedEmail = 'resume@plann.tech';
  const hardcodedPassword = 'resumecuhk1234';

  const onFinish = (values) => {
    const { email, password } = values;
    
    // Check if the entered email and password match the hardcoded credentials
    if (email === hardcodedEmail && password === hardcodedPassword) {
      message.success('Login successful!');
      setAuthenticated(true);  // Set the authenticated state to true
    } else {
      message.error('Invalid email or password!');
    }
  };


  if (authenticated) {
    return <Dashboard />;
  }

  return (
    <Layout style={{ minHeight: '100vh', background: '#fff' }}>
      <Content
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src="/images/cuhklogo.png"
          alt="CUHK Logo"
          style={{ width: '200px', marginBottom: '40px' }}
        />
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          style={{ width: '300px' }}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your Email!' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{
                width: '100%',
                backgroundColor: '#4B2E83',
                borderColor: '#4B2E83',
              }}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
};

export default LoginPage;
