import React from 'react';
import { Table, Button, Space, Input, Typography } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { Title } = Typography;

const UserManagement = () => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} />
          <Button icon={<DeleteOutlined />} danger />
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: '1',
      name: 'John Brown',
      email: 'john@example.com',
      role: 'HR Manager',
      department: 'Human Resources',
    },
    {
      key: '2',
      name: 'Jim Green',
      email: 'jim@example.com',
      role: 'Recruiter',
      department: 'Human Resources',
    },
    // Add more mock data as needed
  ];

  return (
    <div style={{ padding: 24 }}>
      <Title level={3}>User Management</Title>
      <div style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search users"
          prefix={<SearchOutlined />}
          style={{ width: 200, marginRight: 8 }}
        />
        <Button type="primary">Add User</Button>
      </div>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default UserManagement;