import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, message, Table, Popconfirm } from 'antd';
import axios from 'axios';

const { Option } = Select;

const ManageGoals = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [goals, setGoals] = useState([]);
  const [editingGoal, setEditingGoal] = useState(null);
  const [form] = Form.useForm();
  const API_URL = process.env.REACT_APP_API_URL;

  // Fetch roles from the API
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${API_URL}/roles`);
        // Ensure each role has id and role properties
        const formattedRoles = response.data.map(role => ({
          id: role.id,
          role: role.role // Assuming the role name is stored in the 'role' property
        }));
        setRoles(formattedRoles);
      } catch (error) {
        console.error('Error fetching roles:', error);
        message.error('Error fetching roles');
      }
    };
    fetchRoles();
  }, [API_URL]);

  // Define the fetchGoals function to fetch goals from the API
  const fetchGoals = async () => {
    try {
      const response = await axios.get(`${API_URL}/goals`);
      setGoals(response.data);
    } catch (error) {
      message.error('Error fetching goals');
    }
  };

  // Call fetchGoals when the component mounts
  useEffect(() => {
    fetchGoals();  // Fetch goals when the component mounts
  }, [API_URL]);

  // Handle form submission for both Add and Edit
  const onFinish = async (values) => {
    const { goal_name, description } = values;
    try {
      if (editingGoal) {
        // Update existing goal
        await axios.put(`${API_URL}/goals/${editingGoal.id}`, {
          goal_name,
          description,
          roles: selectedRoles.map(roleId => ({ role_id: roleId })) // Send role IDs to API
        });
        message.success('Goal updated successfully');
        setEditingGoal(null); // Clear the editing state
      } else {
        // Add new goal
        await axios.post(`${API_URL}/goals`, {
          goal_name,
          description,
          roles: selectedRoles.map(roleId => ({ role_id: roleId })) // Send role IDs to API
        });
        message.success('Goal added successfully');
      }
      form.resetFields();
      setSelectedRoles([]);
      fetchGoals(); // Refresh the list after adding or editing
    } catch (error) {
      message.error('Error saving goal');
    }
  };

  // Load goal data into form for editing
  const onEdit = (goal) => {
    setEditingGoal(goal);
    form.setFieldsValue({
      goal_name: goal.goal_name,
      description: goal.description,
    });
    setSelectedRoles(goal.roles.map(role => role.id)); // Set the selected roles by role ID
  };

  // Handle deletion of a goal
  const onDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/goals/${id}`);
      message.success('Goal deleted successfully');
      fetchGoals(); // Refresh the list after deletion
    } catch (error) {
      message.error('Error deleting goal');
    }
  };

  // Define the table columns for goals and roles, including Edit and Delete actions
  const columns = [
    {
      title: 'Goal Name',
      dataIndex: 'goal_name',
      key: 'goal_name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Supporting Roles',
      key: 'roles',
      render: (text, record) => (
        <ul>
          {record.roles.map((role, index) => (
            <li key={index}>{role.role}</li> // Display role name
          ))}
        </ul>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => onEdit(record)}>Edit</Button>
          <Popconfirm
            title="Are you sure you want to delete this goal?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>Delete</Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      <h2>{editingGoal ? 'Edit Family Goal' : 'Add Family Goal'}</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label="Goal Name"
          name="goal_name"
          rules={[{ required: true, message: 'Please enter the goal name' }]}
        >
          <Input placeholder="Enter goal name" />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: 'Please enter the description' }]}
        >
          <Input.TextArea placeholder="Enter goal description" rows={4} />
        </Form.Item>

        <Form.Item
          label="Select Supporting Roles"
          rules={[{ required: true, message: 'Please select at least one role' }]}
        >
          <Select
            mode="multiple"
            placeholder="Select supporting roles"
            value={selectedRoles}
            onChange={(value) => setSelectedRoles(value)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: '100%' }}
          >
            {roles.map((role) => (
              <Option key={role.id} value={role.id}>
                {role.role}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {editingGoal ? 'Update Goal' : 'Add Goal'}
          </Button>
          {editingGoal && (
            <Button
              style={{ marginLeft: '10px' }}
              onClick={() => {
                form.resetFields();
                setSelectedRoles([]);
                setEditingGoal(null);
              }}
            >
              Cancel
            </Button>
          )}
        </Form.Item>
      </Form>

      {/* Display the goals in a table */}
      <h2>Family Goals</h2>
      <Table
        dataSource={goals}
        columns={columns}
        rowKey="id"
        pagination={false}  // Disable pagination if not needed
      />
    </div>
  );
};

export default ManageGoals;
