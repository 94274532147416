import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Dashboard from './Pages/Dashboard';
import LoginPage from './Pages/LoginPage';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = (values) => {
    const { username, password } = values;
    // Hardcoded username and password check
    if (username === 'admin' && password === 'password123') {
      setIsLoggedIn(true);
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Route to the login page */}
          <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />

          {/* If not logged in, redirect to login */}
          {!isLoggedIn ? (
            <Route path="*" element={<Navigate to="/login" />} />
          ) : (
            <Route path="/*" element={<Dashboard />} />
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
