import React, { useState } from 'react';
import { Upload, Button, message, Typography, Card, Tag } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Dragger } = Upload;
const { Title, Text } = Typography;

const AnalyzeCandidate = () => {
  const [file, setFile] = useState(null);
  const [analyzedRoles, setAnalyzedRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const handleFileChange = (info) => {
    const { status, originFileObj } = info.file;

    if (status === 'done' || status !== 'uploading') {
      setFile(originFileObj);
      message.success(`${info.file.name} uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} upload failed.`);
    }
  };

  const handleAnalyzeResume = async () => {
    if (!file) {
      message.error('Please upload a resume for analysis.');
      return;
    }

    const formData = new FormData();
    formData.append('resume', file);  // Send the file using the key 'resume'

    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/analyze-resume`, formData);
      const { topRoles } = response.data;
      setAnalyzedRoles(topRoles);  // Display the top roles returned from the server
      message.success('Resume analyzed successfully!');
    } catch (error) {
      console.error('Error analyzing resume:', error);
      message.error('Failed to analyze resume.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <Title level={3}>Analyze Your Resume</Title>
      <Card>
        <Dragger
          name="file"
          multiple={false}
          accept=".pdf,.doc,.docx"
          customRequest={({ onSuccess }) => setTimeout(() => onSuccess('ok'), 0)} // Ensure customRequest is handled
          onChange={handleFileChange}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag a resume file to upload</p>
        </Dragger>
  
        <Button
          type="primary"
          onClick={handleAnalyzeResume}
          style={{ marginTop: '20px', width: '100%' }}
          loading={loading}
        >
          Analyze Resume
        </Button>
      </Card>
  
      {analyzedRoles.length > 0 && (
        <div style={{ marginTop: '20px' }}>
          <Title level={4}>Top Matching Roles</Title>
          {analyzedRoles.map((role, index) => (
            <Card key={index} style={{ marginBottom: '20px', padding: '20px' }}>
              {/* Container for description on the left and match score on the right */}
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {/* Description on the left */}
                <div style={{ flex: 1, paddingRight: '20px', textAlign: 'left' }}>
                  <Title level={4}>{role.name}</Title>
                  <p>{role.description}</p>
                </div>
  
                {/* Match Score Card on the right */}
                <Card
                  bordered={true}
                  style={{
                    width: '150px', // Adjust the width of the Match Score card
                    textAlign: 'center', // Center align the content
                    borderRadius: '8px', // Rounded corners
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                    backgroundColor: '#f6f9fc', // Light background color
                  }}
                  bodyStyle={{ padding: '10px' }} // Customize padding inside the card body
                >
                  <Text
                    style={{
                      fontSize: '16px', // Label size
                      fontWeight: '500', // Bold weight for label
                      color: '#333', 
                    }}
                  >
                    Match Score
                  </Text>
                  <div
                    style={{
                      marginTop: '10px', 
                      fontSize: '32px', 
                      fontWeight: 'bold', 
                      color: '#1890ff',
                    }}
                  >
                    {role.matchScore}
                  </div>
                </Card>
              </div>
  
              {/* Matched Skills and AI Explanation */}
              <div style={{ marginTop: '20px' }}>
                <Title level={5}>Matched Skills with AI-based Explanations</Title>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                  {role.matchedSkills.map((skill, skillIndex) => {
                    // Split the skill string to separate resume skill, job skill, and the AI-generated analysis
                    const [resumeSkill, jobSkill, aiExplanation] = skill.split(' = ');
                    return (
                      <div key={skillIndex} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Tag
                          color="purple"
                          style={{
                            padding: '5px 10px',
                            borderRadius: '20px',
                            fontSize: '14px',
                            border: '1px solid purple',
                            wordBreak: 'break-word',  // Ensures long words break onto the next line
                            whiteSpace: 'normal',  // Prevents content from being forced into a single line
                            maxWidth: '230px'  // Limits the width of the tag
                          }}
                        >
                          {resumeSkill} = {jobSkill}
                        </Tag>
                        {/* AI-based Explanation */}
                        {aiExplanation && (
                          <p style={{
                            fontStyle: 'italic',
                            color: '#7a7a7a',
                            fontSize: '12px',
                            marginTop: '5px'
                          }}>
                            {aiExplanation}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
  
};

export default AnalyzeCandidate;
