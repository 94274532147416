import React, { useState, useEffect } from 'react';
import { Typography, message, Modal, Button, Form, Input, Card, Row, Col } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, ToolOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Title, Paragraph, Text } = Typography;

const ViewRoleDescriptionsPage = () => {
  const [roles, setRoles] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedType, setSelectedType] = useState(null); // To track if showing Key Tasks or KSAs
  const [editMode, setEditMode] = useState(false); // To track if we are editing a role
  const [form] = Form.useForm();

  const API_URL = process.env.REACT_APP_API_URL;

  // Fetch role descriptions from the backend
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${API_URL}/roles`);
        setRoles(response.data);
      } catch (error) {
        console.error('Error fetching roles:', error);
        message.error('Failed to fetch roles.');
      }
    };

    fetchRoles();
  }, []);

  const showModal = (role, type) => {
    if (!role) {
      message.error('Role not found!');
      return;
    }
    setSelectedRole(role);
    setSelectedType(type);
    setVisible(true);
    setEditMode(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setSelectedRole(null);
    setSelectedType(null);
    setEditMode(false);
  };

  const handleEdit = (role) => {
    setSelectedRole(role);
    setEditMode(true);
    setVisible(true);
    form.setFieldsValue({
      role: role.role,
      description: role.description,
      keytask1: role.keytask1,
      keytask2: role.keytask2,
      keytask3: role.keytask3,
      keytask4: role.keytask4,
      keytask5: role.keytask5,
      keytask6: role.keytask6,
      knowledge: role.knowledge,
      skill: role.skill,
      attribute: role.attribute,
    });
  };

  const handleDelete = async (roleId) => {
    try {
      await axios.delete(`${API_URL}/roles/${roleId}`);
      message.success('Role deleted successfully');
      setRoles(roles.filter(role => role.id !== roleId)); // Update roles list in state
    } catch (error) {
      console.error('Error deleting role:', error);
      message.error('Failed to delete role.');
    }
  };

  const handleSave = async () => {
    try {
      const updatedRole = form.getFieldsValue();
      await axios.put(`${API_URL}/roles/${selectedRole.id}`, updatedRole);
      message.success('Role updated successfully');
      setVisible(false);
      setEditMode(false);

      // Update roles list in state
      setRoles(roles.map(role => (role.id === selectedRole.id ? { ...selectedRole, ...updatedRole } : role)));
    } catch (error) {
      console.error('Error updating role:', error);
      message.error('Failed to update role.');
    }
  };

  const renderKeyTasks = () => {
    if (!selectedRole) return null; // Prevent error if selectedRole is null

    return (
      <>
        <Title level={4}>Key Tasks</Title>
        <ul style={{ padding: '10px 20px', listStyleType: 'disc' }}>
          {selectedRole.keytask1 && <li><Paragraph>{selectedRole.keytask1}</Paragraph></li>}
          {selectedRole.keytask2 && <li><Paragraph>{selectedRole.keytask2}</Paragraph></li>}
          {selectedRole.keytask3 && <li><Paragraph>{selectedRole.keytask3}</Paragraph></li>}
          {selectedRole.keytask4 && <li><Paragraph>{selectedRole.keytask4}</Paragraph></li>}
          {selectedRole.keytask5 && <li><Paragraph>{selectedRole.keytask5}</Paragraph></li>}
          {selectedRole.keytask6 && <li><Paragraph>{selectedRole.keytask6}</Paragraph></li>}
        </ul>
      </>
    );
  };

  const renderKSAs = () => {
    if (!selectedRole) return null; // Prevent error if selectedRole is null

    return (
      <>
        <Title level={4}>Knowledge, Skills, Attributes</Title>
        <Paragraph style={{ padding: '10px 0' }}>
          <Text strong>Knowledge:</Text> {selectedRole.knowledge}
        </Paragraph>
        <Paragraph style={{ padding: '10px 0' }}>
          <Text strong>Skills:</Text> {selectedRole.skill}
        </Paragraph>
        <Paragraph style={{ padding: '10px 0' }}>
          <Text strong>Attributes:</Text> {selectedRole.attribute}
        </Paragraph>
      </>
    );
  };

  return (
    <div style={{ padding: '20px' }}>
      <Title level={2}>Role Descriptions</Title>

      <Row gutter={[16, 16]}>
        {roles.map(role => (
          <Col span={24} key={role.id}> {/* Make each card span full width (one row) */}
            <Card
              title={role.role}
              bordered={true}
              actions={[
                <Button type="link" onClick={() => showModal(role, 'keyTasks')}><EyeOutlined /> Key Tasks</Button>,
                <Button type="link" onClick={() => showModal(role, 'ksas')}><ToolOutlined /> KSAs</Button>,
                <Button type="link" onClick={() => handleEdit(role)}><EditOutlined /> Edit</Button>,
                <Button type="link" danger onClick={() => handleDelete(role.id)}><DeleteOutlined /> Delete</Button>,
              ]}
            >
              <Paragraph><strong>Description:</strong> {role.description}</Paragraph>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Modal for displaying or editing role details */}
      <Modal
        title={editMode ? 'Edit Role' : selectedType === 'keyTasks' ? 'Key Tasks' : 'Knowledge, Skills, Attributes'}
        visible={visible}
        onCancel={handleCancel}
        onOk={editMode ? handleSave : handleCancel} // Save if in edit mode, otherwise just close
        okText={editMode ? 'Save' : 'OK'}
        cancelText="Cancel"
        width={800}
      >
        {editMode ? (
          <Form form={form} layout="vertical">
            <Form.Item name="role" label="Role" rules={[{ required: true, message: 'Please input the role name' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please input the role description' }]}>
              <Input.TextArea rows={3} />
            </Form.Item>
            <Form.Item name="keytask1" label="Key Task 1">
              <Input />
            </Form.Item>
            <Form.Item name="keytask2" label="Key Task 2">
              <Input />
            </Form.Item>
            <Form.Item name="keytask3" label="Key Task 3">
              <Input />
            </Form.Item>
            <Form.Item name="keytask4" label="Key Task 4">
              <Input />
            </Form.Item>
            <Form.Item name="keytask5" label="Key Task 5">
              <Input />
            </Form.Item>
            <Form.Item name="keytask6" label="Key Task 6">
              <Input />
            </Form.Item>
            <Form.Item name="knowledge" label="Knowledge">
              <Input.TextArea rows={3} />
            </Form.Item>
            <Form.Item name="skill" label="Skills">
              <Input.TextArea rows={3} />
            </Form.Item>
            <Form.Item name="attribute" label="Attributes">
              <Input.TextArea rows={3} />
            </Form.Item>
          </Form>
        ) : selectedType === 'keyTasks' ? renderKeyTasks() : renderKSAs()}
      </Modal>
    </div>
  );
};

export default ViewRoleDescriptionsPage;
