import React from 'react';
import { Typography, Image, Row, Col, Divider, Card, List, Space } from 'antd';
import { GlobalOutlined, CheckCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

const RecommendationPageContent = ({ recommendation }) => {
  if (!recommendation) {
    return <Text>No recommendation generated yet.</Text>;
  }

  const { metadata, content } = recommendation;

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      {/* Header */}
      <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
        <Image width={100} src="/images/cuhklogo.png" preview={false} alt="CUHK Logo" />
        <Title level={2}>Professional Development Recommendation</Title>
      </div>

      <Divider />

      {/* Letterhead */}
      <Row gutter={24} className="letterhead">
        <Col span={12}>
          <Text strong>{metadata.institution.name}</Text><br />
          <Text strong>{metadata.institution.department}</Text><br />
          {metadata.institution.address.map((line, index) => (
            <Text key={index}>{line}<br /></Text>
          ))}
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Text>{new Date(metadata.date).toLocaleDateString()}</Text>
        </Col>
      </Row>

      <Divider />

      {/* Introduction */}
      <Paragraph style={{ fontSize: '16px' }}>
        {content.introduction}
      </Paragraph>

      {/* Development Areas */}
      <Card title="Key Areas for Development" style={{ marginBottom: '1rem' }}>
        <List
          dataSource={content.developmentAreas}
          renderItem={skill => (
            <List.Item>
              <ArrowRightOutlined style={{ marginRight: '8px' }} />
              {skill}
            </List.Item>
          )}
        />
      </Card>

      {/* Organization Recommendations */}
      <Title level={3}>Recommended Professional Organizations</Title>
      <Row gutter={[24, 24]}>
        {content.organizationRecommendations.map((org, index) => (
          <Col key={index} xs={24} lg={12}>
            <Card title={org.name} bordered={true}>
              <p><strong>Relevant Skills:</strong></p>
              <List
                size="small"
                dataSource={org.relevantSkills}
                renderItem={skill => (
                  <List.Item>
                    <CheckCircleOutlined style={{ marginRight: '8px', color: '#52c41a' }} />
                    {skill}
                  </List.Item>
                )}
              />
              <Divider />
              <p><strong>Recommended Programs:</strong></p>
              <ul>
                {org.courses.map((course, idx) => (
                  <li key={idx}>{course}</li>
                ))}
              </ul>
              <a href={org.url} target="_blank" rel="noopener noreferrer">
                <Space>
                  <GlobalOutlined />
                  Visit Website
                </Space>
              </a>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Next Steps */}
      <Title level={3}>Recommended Next Steps</Title>
      <Card>
        <List
          dataSource={content.nextSteps}
          renderItem={(step, index) => (
            <List.Item>
              <Text strong>{index + 1}. </Text> {step}
            </List.Item>
          )}
        />
      </Card>

      <Divider />

      {/* Signature */}
      <Row>
        <Col span={12} style={{ textAlign: 'left' }}>
          <Text strong>Chief Learning Officer</Text><br />
          <Text>Centre for Family Business</Text><br />
          <Text>CUHK</Text>
        </Col>
      </Row>
    </div>
  );
};

export default RecommendationPageContent;