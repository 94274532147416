import React from 'react';
import { Card, Row, Col, Typography, Tag, Progress, Divider } from 'antd';

const { Title, Text } = Typography;

const ApplicantProfile = () => {
  const applicantData = {
    name: 'Jane Doe',
    email: 'jane.doe@example.com',
    phone: '+1 (555) 123-4567',
    position: 'Chief Learning Officer',
    status: 'Interview Scheduled',
    experience: '10 years',
    education: 'Ph.D. in Organizational Learning, ABC University',
  };

  const skillsComparison = {
    overallMatch: 13,
    matchingSkills: ['family', 'generations', 'values', 'programs', 'expertise', 'planning', 'strategies', 'strategic', 'goals', 'structures', 'confidentiality', 'advisors', 'decision-making'],
    lackingSkills: ['chief learning officer', 'knowledge retention', 'talent development', 'business operations', 'learning opportunities', 'learning environments', 'legacy leadership', 'learning theory', 'adult learning principles', 'learning challenges', 'coaching techniques'],
    similarSkills: ['family', 'wealth', 'governance', 'management', 'succession', 'planning', 'transfer', 'strategies', 'program', 'solutions', 'initiatives', 'trust', 'advisors', 'family office'],
    extraSkills: ['email', 'summary', 'affairs', 'asset allocation strategies', 'relationships', 'investment management', 'risk management', 'estate planning', 'tax optimization', 'wealth preservation', 'portfolio management']
  };

  return (
    <div style={{ padding: 24 }}>
      <Title level={3}>Applicant Profile</Title>
      <Row gutter={16}>
        <Col span={24}>
          <Card>
            <Title level={4}>{applicantData.name}</Title>
            <Text>{applicantData.email} | {applicantData.phone}</Text>
            <br />
            <Text strong>Applying for: </Text>
            <Tag color="blue">{applicantData.position}</Tag>
            <br />
            <Text strong>Status: </Text>
            <Tag color="green">{applicantData.status}</Tag>
            <br />
            <Text strong>Experience: </Text>
            {applicantData.experience}
            <br />
            <Text strong>Education: </Text>
            {applicantData.education}
          </Card>
        </Col>
      </Row>
      <Divider />
      <Title level={4}>Skills Comparison Result: {applicantData.position}</Title>
      <Progress percent={skillsComparison.overallMatch} status="active" />
      <Text>Overall Match: {skillsComparison.overallMatch}%</Text>
      <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
        <Col span={12}>
          <Card title={<><span style={{ color: 'green' }}>Matching Skills</span> <Tag color="green">{skillsComparison.matchingSkills.length}</Tag></>}>
            {skillsComparison.matchingSkills.map((skill, index) => (
              <Tag key={index} color="green" style={{ margin: '0 8px 8px 0' }}>{skill}</Tag>
            ))}
          </Card>
        </Col>
        <Col span={12}>
          <Card title={<><span style={{ color: 'red' }}>Lacking Skills</span> <Tag color="red">{skillsComparison.lackingSkills.length}</Tag></>}>
            {skillsComparison.lackingSkills.map((skill, index) => (
              <Tag key={index} color="red" style={{ margin: '0 8px 8px 0' }}>{skill}</Tag>
            ))}
          </Card>
        </Col>
        <Col span={12}>
          <Card title={<><span style={{ color: 'blue' }}>Similar Skills</span> <Tag color="blue">{skillsComparison.similarSkills.length}</Tag></>}>
            {skillsComparison.similarSkills.map((skill, index) => (
              <Tag key={index} color="blue" style={{ margin: '0 8px 8px 0' }}>{skill}</Tag>
            ))}
          </Card>
        </Col>
        <Col span={12}>
          <Card title={<><span style={{ color: 'purple' }}>Extra Skills</span> <Tag color="purple">{skillsComparison.extraSkills.length}</Tag></>}>
            {skillsComparison.extraSkills.map((skill, index) => (
              <Tag key={index} color="purple" style={{ margin: '0 8px 8px 0' }}>{skill}</Tag>
            ))}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ApplicantProfile;