import React, { useState, useEffect } from 'react';
import { Card, Row, Col, message, Spin, Tag } from 'antd';
import axios from 'axios';

const postProcessResumeData = (data) => {
  const structuredData = {
    personalInfo: {
      name: null,
      email: null,
      phone: null,
      location: null,
    },
    employmentHistory: [],
    education: [],
    skills: [],
    personalityTraits: [],
    workNouns: [],
    sentiment: null,
  };

  if (data && data.resumeEntities) {
    const { skills, personalityTraits, workNouns, sentiment } = data.resumeEntities;
    structuredData.skills = skills || [];
    structuredData.personalityTraits = personalityTraits || [];
    structuredData.workNouns = workNouns || [];
    structuredData.sentiment = sentiment || null;
  }

  return structuredData;
};

const ResumeParsing = ({ parsedData, roleSkills, aiAnalysis }) => {
  const [loading, setLoading] = useState(false);
  const [structuredData, setStructuredData] = useState({
    skills: [],
    personalityTraits: [],
    workNouns: [],
  });
  const [skillComparison, setSkillComparison] = useState({
    matchingSkills: [],
    similarSkills: [],
    lackingSkills: [],
    extraSkills: [],
    relevantAISkills: [],
    relevantAITraits: [],
  });

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (parsedData) {
      const processedData = postProcessResumeData(parsedData);
      setStructuredData(processedData);
    }
  }, [parsedData]);

  const compareSkills = (resumeSkills, roleSkills, aiAnalysis) => {
    // Your compare logic here
  };

  const analyzeSkillsWithAI = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/analyze-skills`, { text: structuredData });
      setStructuredData({
        ...structuredData,
        skills: response.data.skills,
        personalityTraits: response.data.personalityTraits,
        workNouns: response.data.workNouns,
        sentiment: response.data.sentiment,
      });
      const { matchingSkills, lackingSkills, similarSkills, extraSkills, relevantAISkills, relevantAITraits } = compareSkills(
        response.data.skills, roleSkills, response.data
      );
      setSkillComparison({
        matchingSkills, similarSkills, lackingSkills, extraSkills, relevantAISkills, relevantAITraits
      });
      message.success('Skills and personality traits analyzed with Google AI!');
      setLoading(false);
    } catch (error) {
      message.error('Failed to analyze skills with Google AI.');
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <Spin spinning={loading}>

        {/* Work-Related Nouns and Skills */}
        <Row gutter={16} style={{ marginTop: '20px' }}>

    <Card title="Extracted KSAs" style={{ width: '100%' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {structuredData.skills.length > 0 ? (
          structuredData.skills.map((skill, index) => (
            <Tag key={index} color="purple" style={{ marginBottom: '8px' }}>
              {skill}
            </Tag>
          ))
        ) : (
          <p>No skills found in AI analysis</p>
        )}
      </div>
    </Card>

</Row>

      </Spin>
    </div>
  );
};

export default ResumeParsing;
