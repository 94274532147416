import React, { useState } from 'react';
import { Layout, Menu, Card, Statistic, Row, Col, Button, Table, Typography } from 'antd';
import {
  UserOutlined,
  FileOutlined,
  TeamOutlined,
  DashboardOutlined,
  SettingOutlined,
  PlusCircleOutlined,
  EyeOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  FileTextOutlined,
  CheckCircleOutlined,
  ScheduleOutlined,
  ProjectOutlined
} from '@ant-design/icons';
import AddRoleDescription from './AddRoleDescriptionPage';
import ViewRoleDescriptionsPage from './ViewRoleDescriptionPage';
import UserManagement from './UserManagement';
import ApplicantProfile from './ApplicantProfile';
import ManageGoals from './ManageGoals';

const { Header, Content, Sider } = Layout;
const { Title } = Typography;

const AdminDashboard = ({ onLogout }) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState('1');
  const [collapsed, setCollapsed] = useState(false);

  const overviewData = [
    { title: 'Interview Scheduled', value: 33, icon: <ScheduleOutlined /> },
    { title: 'Interview Feedback Pending', value: 2, icon: <FileTextOutlined /> },
    { title: 'Approval Pending', value: 44, icon: <CheckCircleOutlined /> },
    { title: 'Offer Acceptance Pending', value: 13, icon: <UserOutlined /> },
    { title: 'Documentations Pending', value: 17, icon: <FileTextOutlined /> },
    { title: 'Training Pending', value: 3, icon: <TeamOutlined /> },
    { title: 'Supervisor Allocation Pending', value: 5, icon: <UserOutlined /> },
    { title: 'Project Allocation Pending', value: 56, icon: <ProjectOutlined /> },
  ];

  const jobsData = [
    {
      key: '1',
      position: 'Senior Data Analyst',
      positionsLeft: 3,
      applications: 123,
      interviewed: 40,
      rejected: 33,
      feedbackPending: 7,
      offered: 2,
    },
    {
      key: '2',
      position: 'Junior Data Analyst',
      positionsLeft: 7,
      applications: 567,
      interviewed: 22,
      rejected: 20,
      feedbackPending: 2,
      offered: 4,
    },
    // Add more mock data as needed
  ];

  const columns = [
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Positions Left',
      dataIndex: 'positionsLeft',
      key: 'positionsLeft',
    },
    {
      title: 'Applications',
      dataIndex: 'applications',
      key: 'applications',
    },
    {
      title: 'Interviewed',
      dataIndex: 'interviewed',
      key: 'interviewed',
    },
    {
      title: 'Rejected',
      dataIndex: 'rejected',
      key: 'rejected',
    },
    {
      title: 'Feedback Pending',
      dataIndex: 'feedbackPending',
      key: 'feedbackPending',
    },
    {
      title: 'Offered',
      dataIndex: 'offered',
      key: 'offered',
    },
  ];

  const renderContent = () => {
    switch (selectedMenuItem) {
      case '1':
        return (
          <>

            <Row gutter={[16, 16]}>
              {overviewData.map((item, index) => (
                <Col xs={24} sm={12} md={8} lg={6} key={index}>
                  <Card>
                    <Statistic
                      title={item.title}
                      value={item.value}
                      prefix={item.icon}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
            <Title level={4} style={{ margin: '24px 0 16px' }}>Require Attention</Title>
            <Table columns={columns} dataSource={jobsData} />
          </>
        );
        case '2':
          return <ManageGoals />;
      case '3':
        return <AddRoleDescription />;
      case '4':
        return <ViewRoleDescriptionsPage />;
      case '5':
        return <ApplicantProfile />;
        case '6':
          return <UserManagement />;
      default:
        return <h2>Content for menu item {selectedMenuItem}</h2>;
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout>
        <Sider 
          collapsible 
          collapsed={collapsed} 
          onCollapse={setCollapsed}
          style={{ background: '#4B2E83' }}
          trigger={null}
        >
          <Menu 
            theme="dark" 
            defaultSelectedKeys={['1']} 
            mode="inline" 
            onSelect={({ key }) => setSelectedMenuItem(key)}
            style={{ background: '#4B2E83' }}
          >
            <Menu.Item key="1" icon={<DashboardOutlined />}>
              HR Dashboard
            </Menu.Item>
            <Menu.Item key="2" icon={<TeamOutlined />}>
              Office Goals
            </Menu.Item>
            <Menu.SubMenu key="sub1" icon={<FileOutlined />} title="Roles">
              <Menu.Item key="3" icon={<PlusCircleOutlined />}>Add Role Description</Menu.Item>
              <Menu.Item key="4" icon={<EyeOutlined />}>View Role Descriptions</Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="5" icon={<TeamOutlined />}>
              Applicants
            </Menu.Item>
            <Menu.Item key="6" icon={<UserOutlined />}>
              User Management
            </Menu.Item>
            <Menu.Item key="7" icon={<SettingOutlined />}>
              Settings
            </Menu.Item>
          </Menu>
        </Sider>
        <Content style={{ margin: '16px' }}>
          <div style={{ padding: 24, minHeight: 360, background: '#fff', borderRadius: '8px' }}>
            {renderContent()}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminDashboard;