import React, { useState } from 'react';
import { Typography, Input, Button, Form, message, Row, Col } from 'antd';
import axios from 'axios';

const { Title } = Typography;
const { TextArea } = Input;

const AddRoleDescriptionPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const handleAddRole = async (values) => {
    try {
      setLoading(true);
      console.log('Form values before submission:', values); // Log the values
      const response = await axios.post(`${API_URL}/roles`, values);
      message.success(response.data.message);
      form.resetFields();
    } catch (error) {
      console.error('Error adding role:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        message.error(error.response.data.message || 'Failed to add role description.');
      } else {
        message.error('Failed to add role description.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div >
      <Title level={3} style={{ marginBottom: '24px' }}>Add Role Description</Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleAddRole}
      >
        <Row gutter={24}>
          <Col span={24} md={12}>
            <Form.Item
              label="Role Title"
              name="role"
              rules={[{ required: true, message: 'Please enter the role title' }]}
            >
              <Input placeholder="Enter role title" />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="Role Description"
              name="description"
              rules={[{ required: true, message: 'Please enter the role description' }]}
            >
              <TextArea rows={4} placeholder="Enter role description" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          {[1, 2, 3, 4, 5, 6].map((num) => (
            <Col span={24} md={8} key={num}>
              <Form.Item
                label={`Key Task ${num}${num === 6 ? ' (Optional)' : ''}`}
                name={`keytask${num}`}
                rules={num !== 6 ? [{ required: true, message: `Please enter key task ${num}` }] : []}
              >
                <Input placeholder={`Enter key task ${num}${num === 6 ? ' (optional)' : ''}`} />
              </Form.Item>
            </Col>
          ))}
        </Row>

        <Row gutter={24}>
          {/* Knowledge Section */}
          <Col span={24} md={8}>
            <Form.Item
              label="Knowledge"
              name="knowledge"
              rules={[{ required: true, message: 'Please enter knowledge requirements' }]}
            >
              <TextArea rows={3} placeholder="Enter knowledge requirements" />
            </Form.Item>
          </Col>

          {/* Skills Section */}
          <Col span={24} md={8}>
            <Form.Item
              label="Skill"
              name="skill" // Use "skill" instead of "skills"
              rules={[{ required: true, message: 'Please enter skill requirements' }]}
            >
              <TextArea rows={3} placeholder="Enter skill requirements" />
            </Form.Item>
          </Col>

          {/* Attributes Section */}
          <Col span={24} md={8}>
            <Form.Item
              label="Attribute"
              name="attribute" // Use "attribute" instead of "attributes"
              rules={[{ required: true, message: 'Please enter attribute requirements' }]}
            >
              <TextArea rows={3} placeholder="Enter attribute requirements" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Add Role
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddRoleDescriptionPage;
